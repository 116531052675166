import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class HealthyHeartsHome extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(0);
    }

    render(){
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="ContentContainer">
                            <div className="Content ContentHalfPadding">
                                <div className="SectionContainer">
                                    <p className="SectionText">To understand the treatments that combat heart disease in dogs, it's important to understand how a normal, healthy heart works.</p>
                                    <p className="SectionText">The heart is a part of the circulatory system, along with the lungs and blood vessels. The pumping of the heart pushes blood on its journey through the vessels and around the body.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(HealthyHeartsHome);
